import styled from '@emotion/styled';
import { css } from '@emotion/react';
import SearchBox from './SearchBox';

const open = css`
  width: 10em;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.6em;
  padding-left: 1.6em;
  
  .SearchIcon > path {
    fill: red;
  }
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`

const icon = css`
  > svg {
    width: 15px;
  }
  > svg > path {
    fill: #134053;
  }
`
const BigIcon = css`
    &:hover > svg > path {
    fill: #FCBA04;
  }
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  ${({ hasFocus }) => (
    hasFocus ? icon : BigIcon
  )}

  .SearchInput {
    outline: none;
    border: ${({ hasFocus }) => (hasFocus ? "auto" : "none")};
    font-size: 1em;
    transition: 100ms;
    border-radius: 4px;
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    //width: 1em;
    margin-left: 0.3em;
    //color: ${({ hasFocus }) => (hasFocus ? "red" : "blue")}
    pointer-events: none;
  }
`