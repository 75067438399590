import React from 'react';
import {
    Box,
    Heading,
    Link,
    VStack
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'

const SidebarLink = styled(Link)`
    :last-child {
        margin-bottom: 1rem;
    }
`;

const SidebarNav = (props) => {
    console.log(props.pageLocation)
    return (
        <>
        {props.menuItems && 
        <>

            <Box mb="4">
                <Heading as="h3" color="white" textTransform="uppercase" fontSize={{base: '1.25rem', lg: '1.75rem'}}>
                    {props.menuHeading}
                </Heading>
            </Box>
            { props.menuItems.map((item, index) => {
                return (
                    <>
                        <Box mb="5">
                            <Box key={index}>
                                <Link to={item.primary.title_link.url} as={GatsbyLink} color="white" textTransform="uppercase" fontSize={{base: '1rem', lg: '1.25rem'}} lineHeight="1" fontFamily="proxima-nova, sans-serif" fontWeight="700" activeClassName="active" partiallyActive={true} _hover={{color: 'happy-yellow.500'}} _focus={{color: 'happy-yellow.500'}}>
                                        {item.primary.navigation_group_title.text}{' '} 
                                        {/* <ChevronRightIcon w={{base: '3', lg: '4'}} h="4" mb="2" /> */}
                                </Link>
                            </Box>
                            <Box>
                                {props.pageLocation.includes('city-clerk') ? (
                                        <VStack spacing="1" alignItems="left"  ml="2">
                                        {item.items.map((linky, idx) => (
                                            <>
                                                {item.primary.navigation_group_title.text === "City Clerk" && (
                                                           <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink} partiallyActive={true} variant="sidebar-link" activeClassName="active" fontSize={{base: '1rem', lg: '1.25rem'}}>
                                                            {linky.item_title.text}
                                                        </SidebarLink>
                                                )
                                                }
                                            </>
                                            
                                        )
                                        )
                                    }
                                        </VStack>
    
                                ) : props.pageLocation.includes('mayors') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Mayor's Office" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('community-and-economic-development') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Community & Economic Development" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                )  : props.pageLocation.includes('public-works') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Public Works" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('planning-and-building') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Planning & Building" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('human-resources') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Human Resources" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('about') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "About" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('airport-board') ? (
                                    <VStack spacing="1" alignItems="left"  ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Airport Board" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('city-council') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "City Council" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('urban-renewal-agency') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Driggs Urban Renewal Agency" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('parks-recreation-committee') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Parks & Recreation Committee" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                    ) : props.pageLocation.includes('/departments/parks-and-recreation') ? (
                                        <VStack spacing="1" alignItems="left" ml="2">
                                        {item.items.map((linky, idx) => (
                                            <>
                                                {item.primary.navigation_group_title.text === "Parks & Recreation" && (
                                                           <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                            {linky.item_title.text}
                                                        </SidebarLink>
                                                )
                                                }
                                            </>
                                        )
                                        )
                                    }
                                        </VStack>
                                ) : props.pageLocation.includes('planning-zoning-commission') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Planning & Zoning Commission" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                )  : props.pageLocation.includes('joint-housing-authority-board') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Joint Housing Authority Board" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('tree-beautification-committee') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Tree & Beautification Committee" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>

                                ) : props.pageLocation.includes('driggs-design-review-advisory-committee') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Design Review Advisory Committee" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : props.pageLocation.includes('/departments/utilities') ? (
                                    <VStack spacing="1" alignItems="left" ml="2">
                                    {item.items.map((linky, idx) => (
                                        <>
                                            {item.primary.navigation_group_title.text === "Utilities - Water & Sewer" && (
                                                       <SidebarLink key={idx} to={linky.link.url} as={GatsbyLink}  activeClassName="active" variant="sidebar-link">
                                                        {linky.item_title.text}
                                                    </SidebarLink>
                                            )
                                            }
                                        </>
                                    )
                                    )
                                }
                                    </VStack>
                                ) : null }
                            </Box>
                        </Box>
                    </>
                )
            })}
                    </>
        }
        </>
    )
}

export default SidebarNav;