import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
    Heading,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Link
} from '@chakra-ui/react';
import { PrismicRichText } from '@prismicio/react';
import styled from '@emotion/styled';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const StyledPersonBox = styled(Box)`
    &:hover { 
        > * {
            //transform: scale(1.05);
            opacity: 0.8;
         }
    }
`;
const StyledPersonImage = styled(Box)`
    > div {
        border-radius: 1rem;
    }
`;

const PersonModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // fixes scroll to top when modal closes
    const finalRef = React.useRef()

    return (
        <>
        <StyledPersonBox p="2" textAlign="center" width={{base: '100%', md: '50%', lg: '25%'}} onClick={onOpen} cursor="pointer"
                ref={finalRef} >
            <StyledPersonImage borderRadius="md" mb="2">
                <GatsbyImage image={getImage(props.personPhoto)} alt={props.photoAlt} imgStyle={{ borderRadius: '1rem' }}/>
            </StyledPersonImage>
            <Flex
                flexDirection="column"
                flexGrow="1"
                justifyContent="space-between"
                color="white"
                textAlign="center"
                px="2" 
            >
                
                <Box>
                    <Heading as="h4" fontSize="1.5rem" color="elephant.500">
                        {props.personName}
                    </Heading>
                    <Text fontSize="1.125rem" mb="1" fontWeight="600">{props.personTitle}</Text>
                    <Text mb="0">
                        {props.since} <br />
                        Read Bio <ExternalLinkIcon mb="1" /><br />
                    </Text>
                </Box>
            </Flex>
            <Link href={`mailto:${props.email}`}>{props.email}</Link>
       </StyledPersonBox>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered size="xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{""}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex>
                        <Box width="33%" mr="2">
                            <GatsbyImage image={getImage(props.personPhoto)} alt={props.photoAlt} />
                        </Box>
                        <Box>
                            <Heading as="h3">
                                {props.personName}
                            </Heading>
                            <Heading as="h4" fontSize="1.75rem">
                                {props.personTitle}
                            </Heading>
                            <Link href={`mailto:${props.email}`}>{props.email}</Link>
                        </Box>
                    </Flex>
                    <Box mt="4">
                    <PrismicRichText 
    field={props.personContent} />
                    </Box>
                </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

const PersonCard = ({ slice }) => {
    return (
        <Box my={8}>
            <Box mt="12">
                <Heading as="h2" textAlign="center" textTransform="uppercase">
                    {slice.primary?.cards_title.text}
                </Heading>
            </Box>
            <Flex flexWrap="wrap">
                {slice.items.map((item, index) => {
                    return (
                        <PersonModal
                            key={index} 
                            personPhoto={item.image.localFile}
                            photoAlt={item.image.alt}
                            personName={item.name.text}
                            personTitle={item.title.text}
                            personContent={item.content.richText}
                            since={item.since.text}
                            email={item.email.text}
                        />
                    )
                }
                )}
            </Flex>
        </Box>
    )
}

export default PersonCard;