import React from 'react';
import {
    Flex, 
    Box,
    Text,
    Link,
    Heading
} from '@chakra-ui/react';



const ContactCard = ({slice}) => {

    return (
        <Box my="8" id="contact-card">
            <Heading as="h4" textTransform="uppercase" fontSize="1.75rem" mb="2">
                {slice.primary.department_contact_information.text}
            </Heading>
        <Flex flexWrap="wrap">
            {slice.items.map((item, index) => {
                //console.log(item)
                return (
<Box w={{base: '100%', md: '50%', lg: '33.3%', xl: '25%'}} key={index}>

                <Text>
                    <span style={{fontWeight: "bold", fontSize: '1.25rem'}}>
                        {item.contact_title.text}
                    </span><br />
                    {item.contact_name.text}<br />
                    <Link href={`mailto:${item.contact_email.text}`}>
                        {item.contact_email.text}
                    </Link><br />
                    <Link href={`tel:${item.contact_phone.text}`}>
                        {item.contact_phone.text}{' '} {item.contact_extension !== null && (`ext${' '}${item.contact_extension}`)}
                    </Link>
                </Text>
            </Box>
                )
            } 
            )}
        </Flex>
    </Box>
    )
}

export default ContactCard;
