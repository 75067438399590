import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import {
    Box,
} from '@chakra-ui/react'
import { motion } from 'framer-motion';

import styled from '@emotion/styled'

import mooseWalk from '../../../animations/mooseWalk';

import Grass from '../../../images/Grass.svg'
import BackgroundTrees from '../../../images/BackgroundTrees.svg'
import LeftTree from '../../../images/LeftTree.svg'
import RightTree from '../../../images/RightTree.svg'

const AnimationContainer = styled(Box)`
    position: relative;
    height: 100%;
    bottom: 0;
    margin-bottom: -2rem;
    overflow: hidden;
`;

const MovinMoose = motion(Box);

const MooseLottie = () => { 
    const lottieContainer = useRef(null);
    const lottieContainerMobile = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: lottieContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: mooseWalk,
        });
        lottie.loadAnimation({
            container: lottieContainerMobile.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: mooseWalk,
        });

        return () => {
            lottie.destroy();
        };
    }, []);

    return (
            <Box position="absolute" bottom="0" w="100%" pt="4rem">
            <Box style={{
                    backgroundImage: `url(${BackgroundTrees})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'repeat-x',
                    height: '23rem',
                    backgroundPosition: 'center bottom',
                    position: 'relative',
                    }}>
                <Box style={{
                    width: '100%',
                    backgroundImage: `url(${Grass})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'repeat-x',
                    height: '3rem',
                    position: 'absolute',
                    right: '0',
                    zIndex: '10'
                }}
                    bottom={{base: '-23px', md: '-10px'}}
                >
                </Box>
            </Box>
            
                    <Box width={{base: '10%'}} ml="4" position="absolute" left="0" bottom="0">
                        <img src={LeftTree} alt="left tree" />
                    </Box>
                    <Box position="absolute" bottom="0" width="100%" height="100%" overflowX='hidden'>
                        <AnimationContainer>
                            <Box width="100%" position="absolute" left="0" bottom="0">
                            <MovinMoose
                                initial="hidden"
                                whileInView={{
                                    x: [ '0%', '100%' ],
                                }}
                                viewport={{ once: true }}
                                // use whileInView instead of animate to prevent the animation from starting when the page loads
                                // animate={{
                                //     x: [ '0%', '100%' ],
                                // }}
                                transition={{
                                    duration: 32,
                                    ease: 'linear',
                                }}
                            >
                                <Box  w="25%" ref={lottieContainer} />
                                </MovinMoose>
                            </Box>
                            
                        </AnimationContainer>
                    </Box>
                                                
                    <Box width="10%" mr="12" position="absolute" right="0" bottom="0">
                        <img src={RightTree} alt="left tree" />
                    </Box>
                
        </Box>
            
    )

}

export default MooseLottie;

