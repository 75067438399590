import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ChakraProvider } from '@chakra-ui/react'
import './layout.css'
import theme from '../theme'
import Header from '../components/Header/Header'
import StickyHeader from './Header/StickyHeader'
import Footer from './Footer/Footer'
import useSticky from "../hooks/useSticky"

if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]', {
        topOnEmptyHash: false,
        speed: 500
    }
    )
}

const Layout = (props) => {
    const { isSticky, element } = useSticky();
    const data = useStaticQuery(graphql`
    {
      prismicSitewideAlert {
        data {
          show_alert
          alert_text {
            text
          }
          alert_link {
            url
            target
          }
        }
      }
    }
  `)
  const alertbar = data.prismicSitewideAlert.data;

    return (
        <ChakraProvider resetCSS theme={theme}>
                <div className="site">
                    <Header 
                        showAlert={alertbar.show_alert}
                        alertText={alertbar.alert_text.text}
                        alertLink={alertbar.alert_link.url}
                        alertTarget={alertbar.alert_link.target} />
                    <StickyHeader sticky={isSticky} />
                    <main>
                        <div ref={element}>{props.children}</div>
                    </main>
                    <Footer />
                </div>
        </ChakraProvider>
    )
}

export default Layout;