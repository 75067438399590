import React from 'react';
import {
    Box,
    Link,
    Heading,
    Text,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { ArrowForwardIcon, ExternalLinkIcon } from '@chakra-ui/icons';

const FormLinkModule = ({ slice }) => {
    console.log(slice)
    return (
        <Box my="8">
            <Heading mb="2">
                {slice.primary.form_links_title?.text}
            </Heading>
            {slice.items.map((item, index) => {
                return (
                    <Box mb="8" key={index}>
                        <Text mb="1">
                    <span style={{fontWeight: "bold", fontSize: '1.25rem'}}>
                        {item.form_links.document?.data?.form_title.text}
                    </span><br />
                    {item.form_links.document?.data?.form_description.text}
                </Text>
                {item.form_links.document?.data?.form_link.url && <Box>
                    {item.form_links.document?.data?.form_link.url.includes('xpressbillpay') 
                        ? (<Link href={item.form_links.document?.data?.form_link.url} target="_blank" rel="noopener noreferrer">
                            {item.form_links.document?.data?.form_title.text}{' '}(Xpress Bill Pay) <ExternalLinkIcon />
                            </Link>) 
                        : (<Link as={GatsbyLink} to={item.form_links.document?.data?.form_link.url}>
                            {item.form_links.document?.data?.form_title.text}{' '}(online form) <ArrowForwardIcon />
                            </Link>)}
                </Box> }
                {item.form_links.document?.data?.link_to_pdf.url && 
                    <Box>
                        <Link as={GatsbyLink} to={item.form_links.document?.data?.link_to_pdf.url} target="_blank" rel="noopener noreferrer">
                            {item.form_links.document?.data?.form_title.text}{' '}(PDF) <BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}} />
                        </Link>
                    </Box>
                }
                </Box>
                )
            })}
        </Box>
            
    )
}

export default FormLinkModule;
