import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '24px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  .cls-2 {
    fill:#134053
  }
  #Path_29:hover { 
      fill: #FCBA04
  }`


const SearchIconBlue = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"  {...props}>
  <rect class="cls-1" width="30" height="30" rx="3.03386"/>
  <path id="Path_29" data-name="Path 29" class="cls-2" d="M27.56677,24.42757l-5.03519-5.03435a1.20669,1.20669,0,0,0-.8619-.35338h-.82311a10.49877,10.49877,0,1,0-1.81774,1.81774v.82311a1.21183,1.21183,0,0,0,.35338.8619l5.0352,5.03434a1.20666,1.20666,0,0,0,1.70646.00526l.00526-.00526,1.429-1.429a1.217,1.217,0,0,0,.00517-1.7169m-14.997-5.38772a6.45992,6.45992,0,1,1,.00863,0h-.00863"/>
</Logo>
  );
};

export default SearchIconBlue;



