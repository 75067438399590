import React from "react";
import {
    Box,
    Flex,
    Link,
    Text,
} from '@chakra-ui/react'
import NavMenu from "./NavMenu";
import { Link as GatsbyLink } from "gatsby";
import MobileNavMenu from "./NavMenus/MobileNavMenu";
import IconBar from "./IconBar";
import CityOfDriggsLogo from "./CityOfDriggsLogo";
import ChooseLanguage from "./ChooseLanguage";
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Header = (props) => {
    return (
        <>
            <Box as="header" display={{base: 'none', lg: 'block'}} position="absolute" top="0" left="0" w="100%" zIndex="4">
            {props.showAlert === 'True' ? (<Box bg="#e74113" p="2" >
                    <Link href={props.alertLink} target={props.alertTarget} rel="noopener noreferrer">
                        <Text color="white" mb="0" textAlign="center" _hover={{color: 'happy-yellow.500'}}>
                            {props.alertText} <ExternalLinkIcon />
                        </Text>
                    </Link>
                </Box>) : null}
                <IconBar />
                <ChooseLanguage />
                <Flex w="100%" p="4" justifyContent="flex-end" alignItems="center">
                    <Box w="40%" position="absolute" top={props.showAlert === 'True' ? "2.75rem" : "1rem"} left="1rem">
                        <Link as={GatsbyLink} to="/" tabIndex="0" aria-label="City of Driggs logo">
                            <CityOfDriggsLogo />
                        </Link>
                    </Box>
                    <Box w="60%" color="white">
                        <NavMenu />
                    </Box>
                </Flex>
            </Box>
                <MobileNavMenu {...props} />
        </>
    )
}

export default Header;