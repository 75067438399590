import { extendTheme } from '@chakra-ui/react'
import { mode, whiten } from '@chakra-ui/theme-tools'

const theme = extendTheme({
    config: {
        initialColorMode: "light",
        useSystemColorMode: false
    },
    fonts: {
        body: "proxima-nova, sans-serif",
        heading: "Geared Slab, sans-serif"
    },
    styles: {
        global: props => ({
            body: {
              color: mode('#154153', 'white')(props),
              bg: mode('white', '#154153')(props),
              fontSize: '1rem'
            },
            p: {
                marginBottom: '1rem'
            },
            h1: {
                fontFamily: 'Geared Slab, sans-serif',
                textTransform: 'uppercase',
                fontSize: '4rem',
                lineHeight: '4rem'
            },
            h2: {
                fontFamily: 'Geared Slab, sans-serif',
                textTransform: 'uppercase',
                fontSize: '3rem',
                fontWeight: 600
            },
            h3: {
                    fontFamily: 'proxima-nova, sans-serif',
                    fontWeight: 400,
                    marginBottom: '1rem',
                    fontSize: '2rem'
            },
            h4: {
                fontWeight: 600,
                    marginBottom: '1rem',
                    fontSize: '2rem'
            },
            h5: {
                fontSize: '1.25rem',
                fontWeight: '400'
            },
            h6: {
                fontSize: '1.25rem',
                fontWeight: '600'
            },
            a: {
                color: 'pale-sky.500',
                fontWeight: 'bold',
                _hover: {
                    color: 'pale-sky.600'
                }
            },
            ul: {
                marginLeft:'3rem',
                marginBottom: '1rem'
            },
            ol: {
                marginLeft:'3rem',
                marginBottom: '1rem'
            },
          }),
    },    
    colors: {
        'happy-yellow': {
            DEFAULT: '#FCBA04',
            '50': '#FEECB9',
            '100': '#FEE6A5',
            '200': '#FDDB7D',
            '300': '#FDD055',
            '400': '#FCC52C',
            '500': '#FCBA04',
            '600': '#C69202',
            '700': '#8E6902',
            '800': '#574001',
            '900': '#1F1700'
          },
          'elephant': {
            DEFAULT: '#134053',
            '50': '#48ABD5',
            '100': '#38A4D1',
            '200': '#2A8DB7',
            '300': '#227395',
            '400': '#1B5A74',
            '500': '#134053',
            '600': '#091D25',
            '700': '#000000',
            '800': '#000000',
            '900': '#000000'
          },
          'pale-sky': {
            DEFAULT: '#2B70A1',
            '50': '#9EC8E5',
            '100': '#8EBFE1',
            '200': '#6EACD8',
            '300': '#4E9AD0',
            '400': '#3486C1',
            '500': '#2B70A1',
            '600': '#1F5175',
            '700': '#133248',
            '800': '#08141C',
            '900': '#000000'
          },
    },
    components: {
        Menu: {
            parts: ["menu", "item", "list", "button"],
            baseStyle: {
                menu: {
                    fontFamily: 'Geared Slab',
                },
                list: {
                    bg: {
                        base: 'none',
                        lg: 'elephant.500'
                    },
                    border: 'none',
                    marginTop: {
                        base: '10%', lg: '3.1%'
                    },
                },
                item: {
                    color: 'white',
                    fontWeight: '400',
                    _focus: {
                        color: '#FCBA04'
                    },
                    _active: {
                        color: '#FCBA04'
                    }
                }
            }
        },
        Tabs: {
            parts: ["tabs", "tab", "list", "button"],
            baseStyle: {
                button: {
                    backgroundColor: "red"
                }
            },
        },
        Text: {
            baseStyle: {
                color: 'elephant.500',
                marginBottom: '1rem'
            },
            variants: {
                "footer-nav-link": {
                    color: "white",
                    textAlign: { base: 'center', lg: 'left' },
                    fontWeight: "600"
                },
                "sub-footer-nav-link": {
                    textAlign: { base: 'center', lg: 'left' },
                },
            }
        },
        Link: {
            baseStyle: {
                color: 'pale-sky.500',
                fontSize: '1rem',
                fontWeight: '600',
                _hover: {
                    textDecoration: 'none',
                    color: 'elephant.500'
                },
                _focus: {
                    color: 'elephant.500'
                }
            },
            variants: {
                "sidebar-link": {
                    color: 'white',
                    fontSize: '1.25rem',
                    fontWeight: '400',
                    _hover: {
                        color: 'happy-yellow.500',
                    },
                    _active: {
                        color: 'happy-yellow.500'
                    },
                    _focus: {
                        color: 'happy-yellow.500'
                    }
                },
                "menu-link": {
                    color: 'white',
                    fontWeight: '400',
                    _hover: {
                        color: 'happy-yellow.500'
                    }
                },
                "footer-nav-link": {
                    color: 'white',
                    fontSize: '1.25rem',
                    textAlign: { base: 'center', lg: 'left' },
                    _hover: {
                        color: 'happy-yellow.500',
                        textDecoration: 'none'
                    },
                    active: {
                        color: 'happy-yellow.500'
                    }                
                },
                "sub-footer-nav-link": {
                    color: 'white',
                    fontSize: '1rem',
                    textAlign: { base: 'center', lg: 'left' },
                    _hover: {
                        color: 'happy-yellow.500',
                        textDecoration: 'none'
                    },
                    active: {
                        color: 'happy-yellow.500'
                    }                
                }
            }
        },
        Heading: {
            variants: {
                "page-title": {
                    textTransform: 'uppercase',
                    fontSize: '3rem'
                },
                "page-subtitle": {
                    fontFamily: 'proxima-nova, sans-serif',
                    fontWeight: 400,
                    marginBottom: '1rem',
                    fontSize: '1.5rem'

                },
                "footer-title": {
                    fontSize: '1.5rem'
                }
            }
        },
    }
})

export default theme