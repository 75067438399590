import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const OnlineBillPayIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="41.053" height="31.929" viewBox="0 0 41.053 31.929" {...props}>
  <path id="Path_1145" data-name="Path 1145" d="M275.141,352.062a3.421,3.421,0,0,0,3.421,3.421h34.211a3.421,3.421,0,0,0,3.421-3.421V339.519H275.141Zm13.684-4.846a.857.857,0,0,1,.855-.855h9.693a.857.857,0,0,1,.855.855v2.85a.857.857,0,0,1-.855.855H289.68a.857.857,0,0,1-.855-.855Zm-9.123,0a.858.858,0,0,1,.855-.855h5.132a.857.857,0,0,1,.855.855v2.85a.857.857,0,0,1-.855.855h-5.132a.858.858,0,0,1-.855-.855Zm36.492-20.242V330.4H275.141v-3.421a3.422,3.422,0,0,1,3.421-3.421h34.211a3.422,3.422,0,0,1,3.421,3.421" transform="translate(-275.141 -323.554)" fill="#fff"/>
</Logo>
  )
}

  export default OnlineBillPayIcon;
