import React from 'react';

import {
    Flex
} from '@chakra-ui/react';
import Gallery from '@browniebroke/gatsby-image-gallery'

const PhotoGallery = ({ slice }) => {

    const CustomWrapper = ({ children, onClick }) => (
        <Flex w="100%" flexWrap="wrap"className="custom-wrapper" onClick={onClick} role="button" width={{base: '100%', md: '33%', lg: '25%'}} p="2">
          { children }
        </Flex>
      )
      
      const lightboxOptions = {
        enableZoom: false,
        closeLabel: 'Close',
      }
    
      const images = slice.items.map(({ image }) => ({
        ...image.localFile.childImageSharp,
        caption: image.alt,
      })
      )

      console.log(slice.items)

    return (
        <Flex className="gallery-container" mb="12" justifyContent="center">
            <Gallery images={images}
                customWrapper={CustomWrapper}
                lightboxOptions={lightboxOptions}
                gutter="0"
            />
        </Flex>
    )
}

export default PhotoGallery;