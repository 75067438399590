import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Flex,
    Box,
    Heading,
    Link
} from '@chakra-ui/react'
import { PrismicRichText } from '@prismicio/react';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { StaticImage } from 'gatsby-plugin-image';
import { CustomLink } from '../../utils/customLink';
import { MdOutlineOndemandVideo } from 'react-icons/md';

const MeetingTabs = ({ slice }) => { 
    return (
        <Tabs isFitted variant='enclosed-colored' my="12">
            <TabList mb='1em'>
                <Tab fontSize="1.125rem" fontWeight="700">Upcoming Meeting {slice.primary.upcoming_meeting_date}</Tab>
                <Tab fontSize="1.125rem" fontWeight="700">Previous Meeting {slice.primary.previous_meeting_date}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Flex w="100%">
                        <Box width="100%">
                            <Flex w="100%" justifyContent="space-between" mb="4" flexWrap="wrap">
                                <Box>
                                    <Heading as="h3" fontSize="1.5rem" textTransform="uppercase">
                                        Meeting Agenda Packet
                                    </Heading>
                                </Box>
                                <Box>
                                    <Link 
                                        href={slice.primary.link_to_upcoming_meeting_packet_pdf.url} 
                                        fontWeight="700"
                                        fontSize="1.25rem"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >
                                            Download Agenda Packet PDF <span><BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>
                                    </Link>
                                </Box>
                                <Box>
                                <Link 
                                    href={slice.primary.zoom_meeting_link?.text} 
                                    fontWeight="700"
                                    fontSize="1.25rem"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Watch Meeting Live On Zoom <span><MdOutlineOndemandVideo style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>
                                </Link>
                            {/* <Heading as="h3" fontSize="1.5rem" textTransform="uppercase">
                                Watch Meeting Live On Vimeo <MdOutlineOndemandVideo />
                            </Heading>
                            <Box>
                                <a href={slice.primary.zoom_meeting_link?.text} target="_blank" rel="noopener noreferrer">
                                    <StaticImage src="../../images/zoomLogo.svg" alt="Zoom Meeting Link" placeholder="blurred"/>
                                </a>
                            </Box> */}
                        </Box>
                            </Flex>
                            <Box>
                                <PrismicRichText 
    field={slice.primary.agenda_header?.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }} />
                            </Box>
                            <Box>
                                <PrismicRichText 
    field={slice.primary.upcoming_meeting_agenda_packet?.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }}/>
                            </Box>
                            <Box>
                                <PrismicRichText 
    field={slice.primary.instructions?.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }} />
                            </Box> 
                            
                        </Box>
                       
                    </Flex>
                </TabPanel>
                <TabPanel>
                    <Flex w="100%" flexWrap="wrap">
                        <Box width={{base: '100%', lg: '50%'}} pr="8">
                                <Box mb="4">
                                    <Link 
                                        href={slice.primary.link_to_previous_meeting_packet_pdf?.url} 
                                        fontWeight="700"
                                        fontSize="1.25rem"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            {slice.primary.previous_meeting_date} Agenda Packet PDF <span><BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>
                                    </Link>
                                </Box>
                                <Box>
                                    <PrismicRichText 
    field={slice.primary.archive?.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }}/>
                                </Box>
                        </Box>
                        <Box width={{base: '100%', lg: '50%'}}>
                            <Heading as="h3" fontSize="1.5rem" textTransform="uppercase">
                                Watch Previous Meeting
                            </Heading>
                            <Box>
                                <Box dangerouslySetInnerHTML={{ __html: slice.primary.vimeo_meeting_iframe_embed_code.richText[0]?.text }} />
                            </Box>
                        </Box>
                    </Flex>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
};

export default MeetingTabs;