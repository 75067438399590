import React from 'react'
import { BgImage } from 'gbimage-bridge'
import {
  Box,
  Heading,
  Flex,
  Text
} from '@chakra-ui/react'
import Tetons from './Tetons'
import ParallaxTitle from './ParallaxTitle'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const PageHero = (props) => {
    // Explicitly delcare props and destructure the other properties:
  const { className, children, ...rest } = props;

  return (
    <Box display={{base: 'block', lg: 'none'}}>
    {/* <BgImage
        tag="section"
        className={className}
        image={props.heroImage}
    > */}
    <Box position="relative">
      <GatsbyImage image={getImage(props.heroImage)} alt="Driggs Airport" />
    <Flex w="100%" flexDirection="column" justifyContent="flex-end" paddingBottom="5%" alignItems="left" position="absolute" top="50%">
    <ParallaxTitle>
      <Box px="4" zIndex="1">
        <Heading color="white" as="h1" fontSize={{base: "2rem", lg: "4rem"}} textTransform="uppercase" textShadow="1px 1px 5px #00000050;">

          {props.pageTitle}
        </Heading>
      </Box>
    </ParallaxTitle>
  </Flex>
  {props.photoCredit && (
    <Box position="absolute" bottom="0" left="2">
    <Text color="white" textTransform="uppercase" fontWeight="bold" zIndex="100" mb="0" fontSize="0.7rem" textShadow="0px 0px 2px #0000007a;">
      Photo: {props.photoCredit}
    </Text>
  </Box>
  )}
  <Box position="absolute" bottom="0" right="0">
    <Tetons 
      boxWidth="60%"
    />
  </Box>
    </Box>
    {/* </BgImage> */}
    
    </Box>
  )
}

const StyledBackgroundSection = styled(PageHero)`
    width: 100%;
    min-height: 250px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
`;

export default StyledBackgroundSection