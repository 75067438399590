import React, { useRef, forwardRef } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Button,
  Box,
  Heading,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import styled from "@emotion/styled";

import ApplyForIcon from "../../../images/icons/ApplyForIcon.svg";
import ContactIcon from "../../../images/icons/ContactIcon.svg";
import FindIcon from "../../../images/icons/FindIcon.svg";
import PayIcon from "../../../images/icons/PayIcon.svg";
import ReserveIcon from "../../../images/icons/ReserveIcon.svg";
import RequestIcon from "../../../images/icons/RequestIcon.svg";
import SearchIcon from "../../../images/icons/SearchIcon.svg";
import SignUpForIcon from "../../../images/icons/SignUpIcon.svg";
import StartABusinessIcon from "../../../images/icons/StartABusinessIcon.svg";
import StayInformedIcon from "../../../images/icons/StayInformedIcon.svg";
import SubmitIcon from "../../../images/icons/SubmitIcon.svg";

const GridContainer = styled(Box)`
  column-count: 3;
  column-gap: 3rem;
  @media (max-width: 48em) {
    column-count: 1;
   }
`;

const Item = styled(GridItem)`
  margin: 0;
  display: grid;
  grid-template-rows: 3fr auto;
  margin-bottom: 1rem;
  break-inside: avoid;
  @media (max-width: 48em) {
    grid-template-columns: 1fr;
   }
`;

const HowDoIMenu = ({ sticky }) => {

  const data = useStaticQuery(graphql`
    {
      allPrismicMainNavigation(filter: {tags: {eq: "How Do I?"}}) {
        edges {
          node {
            data {
              title {
                text
              }
              column {
                menu_group {
                  document {
                    ... on PrismicNavigationMenu {
                      data {
                        body {
                          ... on PrismicNavigationMenuDataBodyMenuItem {
                            id
                            slice_type
                            primary {
                              heading_link {
                                url
                                uid
                              }
                              heading {
                                text
                              }
                            }
                            items {
                              link_label {
                                text
                              }
                              link {
                                url
                                target
                              }
                              anchor {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicMainNavigation.edges[0].node.data.column;

  const menuGroup = document.map((item, index) => {
    return (
      item
    )
  });

  return (
    <Flex justifyContent={{base: 'flex-start', md: 'flex-end'}}alignItems="center">
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={
                isOpen ? (
                  <ChevronUpIcon w={4} h={4} />
                ) : (
                  <ChevronDownIcon w={4} h={4} />
                )
              }
              isActive={isOpen}
              activeClassName="active"
              variant="ghost"
              textTransform="uppercase"
              fontSize="1.5rem"
              fontWeight="bold"
              fontFamily="Geared Slab"
              _hover={{ background: "none", color: "#FCBA04" }}
              _active={{ background: "none", color: "#FCBA04" }}
              zIndex="100"
              textShadow={!sticky ? (`1px 1px 4px #00000075`) : (`none`)}
            >
              How Do I?
            </MenuButton>
            <MenuList
              p={{ base: 6, lg: 4 }}
              // className="how-do-i-menu"
              marginTop={{ base: "25%", lg: "21px" }}
            >
              <GridContainer> 
                    {menuGroup.map((item) => {
                      return (
                        <>
                          {item.menu_group.document.data.body.map((menuItem, idx) => {
                            return (
                             <Item key={idx} height="min-content">
                               {menuItem.primary.heading_link.uid !== null 
                                ? (
                                    <Link to={menuItem.primary.heading_link.url} as={GatsbyLink} activeClassName="active" ispartiallyActive={true}>
                                    <Heading  as="h6" fontSize="1.3rem" textTransform="capitalize" color="white" whiteSpace="nowrap" _hover={{color: 'happy-yellow.500'}}>
                                      {menuItem.primary.heading.text}
                                    </Heading>
                                    </Link>
                                    ) 
                                : ( 
                                    <HStack borderBottom="1px solid #fcba0470">
                                      <Box>{menuItem.primary.heading.text === "Apply For" 
                                        ? <img src={ApplyForIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Contact" 
                                        ? <img src={ContactIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Find" 
                                        ? <img src={FindIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Pay" 
                                        ? <img src={PayIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Request" 
                                        ? <img src={RequestIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Search" 
                                        ? <img src={SearchIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Reserve" 
                                        ? <img src={ReserveIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Sign Up For" 
                                        ? <img src={SignUpForIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        : 
                                        menuItem.primary.heading.text === "Start A Business" 
                                        ? <img src={StartABusinessIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Stay Informed" 
                                        ? <img src={StayInformedIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :
                                        menuItem.primary.heading.text === "Submit" 
                                        ? <img src={SubmitIcon} style={{marginBottom: '6px'}} alt=""/> 
                                        :  null}
                                      </Box>   
                                      <Heading  as="h6" fontSize="1.3rem" textTransform="uppercase" color="happy-yellow.500" whiteSpace="nowrap"  >
                                        {menuItem.primary.heading.text}
                                      </Heading>
                                    </HStack>
                                    )
                                }
                                {menuItem.items.map((item, index_) => {
                                  return (
                                   <>
                                     {item.link_label.text && (
                                      <Box key={index_}>
                                       <>
                                          {item.link.target === "_blank" 
                                            ? (<MenuItem key={index_}
                                              className="sub-menu-item"
                                              whiteSpace="nowrap"
                                              padding="0" 
                                              as="a" 
                                              href={item.link.url} 
                                              target="_blank" 
                                              rel="noopener noreferrer"
                                              activeClassName="active"
                                              ispartiallyActive={true}
                                              variant="menu-link"
                                              _hover={{ background: "none", color: "#FCBA04" }}
                                              _active={{ background: "none", color: "#FCBA04" }}
                                              >
                                                {item.link_label.text}</MenuItem>
                                              ) 
                                            : (<MenuItem key={index_}
                                                className="sub-menu-item"
                                                whiteSpace="nowrap"
                                                padding="0"
                                                as={GatsbyLink}
                                                to={item.anchor.text ? (`${item.link.url}#${item.anchor.text}`) : item.link.url}
                                                target={item.link.target}
                                                _hover={{ background: "none", color: "#FCBA04" }}
                                                _active={{ background: "none", color: "#FCBA04" }}
                                                activeClassName="active"
                                                ispartiallyActive={true}
                                                variant="menu-link">
                                                  {item.link_label.text}
                                              </MenuItem>
                                              )}
                                        </>
                                  </Box>
                                   )} 
                                   </> 
                                  )
                                })}

                                {/* This method uses Reach Router's navigage method to navigate to the page. */}
                                {/* {menuItem.items.map((item, index_) => {
                                  return (
                                   <>
                                     {item.link_label.text && (
                                      <MenuItem
                                        key={index_}
                                        className="sub-menu-item"
                                        whiteSpace="nowrap"
                                        padding="0"
                                        target={item.link.target}
                                        onClick={() => {navigate(item.link.url)}}
                                        >
                                        {item.link_label.text}
                                      </MenuItem>
                                   )} 
                                   </> 
                                  )
                                })} */}
                                
                             </Item>
                            )
                          })}
                        </>
                      )
                    }
                    )}
                 </GridContainer>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default HowDoIMenu;
