import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Link,
} from '@chakra-ui/react';
import SidebarNav from './SidebarNav';

const Sidebar = (props) => {
        return (
        <Box bg="pale-sky.500" p="6" flex="1" pb={{base: '12rem', md: '36rem'}} h="100%" >
            <Flex flexDirection="column" w="100%" h="100%">
            {props.menuItems && 
                <Box mb={24} mt="4">
                    <SidebarNav
                        menuItems={props.menuItems}
                        menuHeading={props.menuHeading}
                        pageLocation={props.pageLocation}
                    /> 
                </Box> }
                {/* Pull menu items from top level menus */}
                {/* {props.menuItems && (
                <Box mb="12">
                    <Heading as="h5" color="white" textTransform="uppercase" fontSize="1.75rem">
                        {props.menuHeading}
                    </Heading>
                    <Box pl="2">
                    {props.menuItems?.map((item, index) => {
                        return (
                            <Box key="index" my="2">
                                <Link as={GatsbyLink} to={item.link.url} target={item.link.target} variant="sidebar-link" activeClassName="active">
                                    {item.link_label.text}
                                    </Link>
                            </Box>
                        )
                    }
                    )}
                    </Box>
                </Box>
                )} */}
                <Box>
                <Heading as="h5" color="white" textTransform="uppercase" fontSize={{base: '1.25rem', lg: '1.75rem'}}>
                    Contact Info
                </Heading>
                <Heading as="h6" color="white" fontSize="1.25rem" fontFamily="proxima-nova, sans-serif">
                    City Hall
                </Heading>
                <Text color="white">
                    60 South Main Street<br />
                    P.O. Box 48<br />
                    Driggs, Idaho 83422
                </Text>
                <Text color="white">
                <strong>Hours:</strong> Monday-Thursday 8:30 a.m. - 5:00 p.m.<br />
                Friday 8:30 a.m. - 1:00 p.m.
            </Text>
                <Text color="white">
                    <strong>Phone:</strong> <Link color="white" href="tel:208-354-2362" _hover={{color: 'happy-yellow.500'}}>(208) 354-2362</Link>
                </Text>
                <Text color="white">
                    <strong>General Email:</strong> <Link href="mailto:info@driggsidaho.org" color="white" _hover={{color: 'happy-yellow.500'}}>info@driggsidaho.org</Link>
                </Text> 
                </Box>
            </Flex>
        </Box>
    )
}

export default Sidebar;