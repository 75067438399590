import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`


const PhoneIcon = (props) => {
  return (

  <Logo xmlns="http://www.w3.org/2000/svg" width="31.929" height="31.929" viewBox="0 0 31.929 31.929" {...props}>
    <path id="Path_1147" data-name="Path 1147" d="M265.91,318.422l-6.486-1.5a1.505,1.505,0,0,0-1.715.866l-2.993,6.985a1.492,1.492,0,0,0,.43,1.746l3.779,3.093a23.114,23.114,0,0,1-11.051,11.051l-3.093-3.779a1.5,1.5,0,0,0-1.747-.43l-6.984,2.993a1.514,1.514,0,0,0-.873,1.722l1.5,6.485a1.5,1.5,0,0,0,1.459,1.16,28.933,28.933,0,0,0,28.935-28.935,1.494,1.494,0,0,0-1.16-1.459" transform="translate(-235.141 -316.887)" className="cls-1" />
  </Logo>

  );
};

export default PhoneIcon;
