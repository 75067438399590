import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const MeetingIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="32.3" height="36.915" viewBox="0 0 32.3 36.915" {...props}>
  <path id="Path_1141" data-name="Path 1141" d="M230.038,327.27h-23.12a5.788,5.788,0,0,1,4.522-4.474,3.454,3.454,0,0,0,2.447,1.014H218.5a3.461,3.461,0,0,0,0-6.921h-4.614a3.448,3.448,0,0,0-3.321,2.54,9.214,9.214,0,0,0-7.11,7.842h-3.412a1.154,1.154,0,0,0-1.154,1.154v1.153a1.153,1.153,0,0,0,1.154,1.153h29.993a1.153,1.153,0,0,0,1.153-1.153v-1.153a1.154,1.154,0,0,0-1.153-1.154m-2.307,23.072h-2.756l1.6-17.3H203.505l1.6,17.3h-2.755a1.154,1.154,0,0,0-1.154,1.153v1.154a1.154,1.154,0,0,0,1.154,1.154h25.379a1.154,1.154,0,0,0,1.153-1.154v-1.154a1.154,1.154,0,0,0-1.153-1.153" transform="translate(-198.891 -316.888)" fill="#fff"/>
</Logo>
  );
};

export default MeetingIcon;
