import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const MayorsCommentBoxIcon = (props) => {
  return (

<Logo xmlns="http://www.w3.org/2000/svg" width="37.784" height="37.784" viewBox="0 0 37.784 37.784" {...props}>
  <path id="Path_1144" data-name="Path 1144" d="M364.379,332.827h11.807a1.18,1.18,0,0,0,1.181-1.18v-1.181a1.18,1.18,0,0,0-1.181-1.18H364.379a1.179,1.179,0,0,0-1.18,1.18v1.181a1.179,1.179,0,0,0,1.18,1.18m-1.18,5.9a1.179,1.179,0,0,0,1.18,1.18h11.807a1.18,1.18,0,0,0,1.181-1.18V337.55a1.18,1.18,0,0,0-1.181-1.18H364.379a1.179,1.179,0,0,0-1.18,1.18Zm7.084,8.938a5.892,5.892,0,0,1-3.458-1.121L351.391,335.4V351.13a3.542,3.542,0,0,0,3.542,3.542h30.7a3.543,3.543,0,0,0,3.543-3.542V335.4L373.74,346.549A5.9,5.9,0,0,1,370.283,347.67Zm17.534-18.757c-.654-.513-1.272-.994-2.185-1.684v-3.257a3.542,3.542,0,0,0-3.542-3.542h-5.723l-.668-.485c-1.241-.906-3.705-3.083-5.417-3.058-1.713-.025-4.176,2.152-5.418,3.058l-.666.485h-5.723a3.542,3.542,0,0,0-3.543,3.542v3.257c-.913.689-1.532,1.171-2.185,1.684a3.541,3.541,0,0,0-1.356,2.787v.786l7.084,5.118V323.972h23.615V337.6l7.084-5.118V331.7a3.542,3.542,0,0,0-1.358-2.787" transform="translate(-351.391 -316.887)" fill="#fff"/>
</Logo>
  
    );
  }

  export default MayorsCommentBoxIcon;
