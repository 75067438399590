import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Flex,
  Link,
  Heading,
  CloseButton,
  IconButton,
  Slide,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Text
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import MobileHowDoIMenu from "./MobileHowDoIMenu";
import MobileInsideCityHallMenu from "./MobileInsideCityHallMenu";
import MobileCommunityMenu from "./MobileCommunityMenu";
import MobileSearch from "../../Search/MobileSearch";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const searchIndices = [
  { name: `News`, title: `News`, type: `newsHit` },
  { name: `About Pages`, title: `About Pages`, type: `aboutHit` },
  { name: `Airport Board Pages`, title: `Airport Pages`, type: `airportHit` },
  { name: `Bids RFPs Projects`, title: `Bids, RFPs &amp; Projects`, type: `airportBoardHit` },
  { name: `Pages`, title: `Pages`, type: `pageHit` },
  { name: `Board Pages`, title: `Board Pages`, type: `boardPagesHit` },
  { name: `City Clerk Forms`, title: `City Clerk Forms`, type: `cityClerkHit` },
  { name: `City Clerk Pages`, title: `City Clerk Pages`, type: `cityClerkPagesHit` },
  { name: `City Council Pages`, title: `City Council Pages`, type: `cityCouncilHit` },
  { name: `Community Development Pages`, title: `Community Development Pages`, type: `communityDevelopmentHit` },
  { name: `Departments`, title: `Departments`, type: `departmentHit` },
  { name: `Design Review Adivsory Committee Pages`, title: `Design Review Advisory Committee Pages`, type: `designReviewAdvisoryCommitteeHit` },
  { name: `Parks Recreation Board Pages`, title: `Parks &amp; Recreation Board Page`, type: `parksAndRecreationHit` },
  { name: `Parks Recreation Pages`, title: `Parks &amp; Recreation Pages`, type: `parksAndRecreationPagesHit` },
  { name: `Planning Building Pages`, title: `Planning &amp; Building Pages`, type: `planningBuildingHit` },
  { name: `Planning Zoning Board Pages`, title: `Planning &amp; Zoning Board Pages`, type: `planningZoningHit` },
  { name: `Projects`, title: `Projects`, type: `projectHit` },
  { name: `Public Works Pages`, title: `Public Works Pages`, type: `publicWorksHit` },
  { name: `Tree Beautification Pages`, title: `Tree &amp; Beautification Pages`, type: `treeBeautificationHit` },
  { name: `Urban Renewal Pages`, title: `Urban Renewal Pages`, type: `urbanRenewalHit` },

]


const MobileNavMenu = (props) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const { isOpen, onToggle } = useDisclosure()
  

  return (
    <>
    {props.showAlert === 'True' ? (<Box bg="#e74113" p="2" >
                    <Link href={props.alertLink} target={props.alertTarget} rel="noopener noreferrer">
                        <Text color="white" mb="0" textAlign="center" _hover={{color: 'happy-yellow.500'}}>
                            {props.alertText} <ExternalLinkIcon />
                        </Text>
                    </Link>
                </Box>) : null}
    <Box bgColor="#134053" as="header" p="4" position="sticky" top="0" zIndex="10" display={{base: 'block', lg: 'none'}}>
        <Flex
          as="nav"
          align="center"
          justify="space-between"
          wrap="wrap"
          paddingLeft="0.75rem"
          paddingRight="0.75rem"
          bg="shadow.100"
          color="steel.400"
          {...props}
        >
          <Box w="75%">
            <Link to="/" as={GatsbyLink}>
              <Heading fontSize="1.65rem" textTransform="uppercase" color="white">City of Driggs</Heading>
            </Link>
          </Box>
          <Box onClick={onToggle}>
            {show ? (
              <CloseButton color="happy-yellow.500" />
            ) : (
              <IconButton
                aria-label="menu"
                icon={<HamburgerIcon />}
                variant="outline"
                borderColor="white"
                color="white"
                size="sm"
              />
            )}
          </Box>
        </Flex>
        </Box>
      {isOpen && (
        <Slide
        direction="right" in={isOpen} style={{ zIndex: 10 }}
        >
          <Box
            position="absolute"
            right="0"
            top="0"
            bg="elephant.500"
            color="white"
            w="100vw"
            zIndex="999"
            h="100%"
            overflowY="scroll"
            overflowX="hidden"
            px=".75rem"
            //display={{ base: show ? "block" : "none" }}
            initial={{ display: "none" }}
            //animate={{ width: 300}}
            animate={{
              display: "block",
            }}
            transition={{ duration: 1.5 }}
            exit={{
              width: 0,
              transition: { duration: 1.5 },
            }}
          >
            <Box >
              <Flex width="100%" justifyContent="flex-end">
                <Box onClick={onToggle} p='4'>
                  <CloseButton />
                </Box>
              </Flex>
              <Accordion allowToggle>
              <AccordionItem border="none">
                  <AccordionButton>
                    <Box flex='1' textAlign='center'>
                      <Heading as="h3" mb="0" textTransform="uppercase" pl="1.25rem" fontSize="1.75rem">How Do I?</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel pb={4}>
                  <MobileHowDoIMenu />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border="none">
                  <AccordionButton>
                    <Box flex='1' textAlign='center'>
                      <Heading as="h3" mb="0" textTransform="uppercase" pl="1.25rem" fontSize="1.75rem">Inside City Hall</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel pb={4}>
                  <MobileInsideCityHallMenu />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border="none">
                  <AccordionButton>
                    <Box flex='1' textAlign='center'>
                      <Heading as="h3" mb="0" textTransform="uppercase" pl="1.25rem" fontSize="1.75rem">Community</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel pb={4}>
                  <MobileCommunityMenu />
                </AccordionPanel>
              </AccordionItem>
              </Accordion>
              <Box>
              <MobileSearch indices={searchIndices} />
              </Box>
            </Box>
            </Box>
            </Slide>
      )}
    </>
  );
};

export default MobileNavMenu;
