import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import SearchIcon from '../Header/Icons/SearchIcon'
import SearchIconBlue from '../Header/Icons/SearchIconBlue'
import styled from '@emotion/styled'

const StyledForm = styled.form`
> input {
    padding: 0.25rem;
}
    /* &:hover > svg > path {
    fill: #FCBA04;
  } */
`

export default connectSearchBox(
    ({ refine, currentRefinement, className, onFocus }) => (
            <StyledForm className={className}>
                <input
                    className='SearchInput'
                    type='text'
                    placeholder='Search'
                    aria-label="Search"
                    onChange={e => refine(e.target.value)}
                    value={currentRefinement}
                    onFocus={onFocus}
                />
                {/* <SearchIconBlue className='SearchIcon'/> */}
                <SearchIcon className='SearchIcon' fill="white" />
            </StyledForm>
    )
)