import algoliasearch from 'algoliasearch/lite';
import { createRef, default as React, useState, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { ThemeProvider } from '@emotion/react';
import StyledSearchBox from './StyledSearchBox';
import StyledSearchResult from './StyledSearchResult';
import StyledSearchRoot from './StyledSearchRoot';
import useClickOutside from './UseClickOutside';

const theme = {
    foreground: "white",
    background: "white",
    faded: "#888",
  }
  export default function Search({ indices }) {
    const rootRef = createRef()
    const [query, setQuery] = useState()
    const [hasFocus, setFocus] = useState(false)
    const algoliaClient = useMemo(
      () =>
        algoliasearch(
          process.env.GATSBY_ALGOLIA_APP_ID,
          process.env.GATSBY_ALGOLIA_SEARCH_KEY
        ),
        
      []
    )
    useClickOutside(rootRef, () => setFocus(false))

    // Function to remove blank search results
    const searchClient = {
      ...algoliaClient,
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }
    
        return algoliaClient.search(requests);
      },
    };
    
    return (
      <ThemeProvider theme={theme}>
        <StyledSearchRoot ref={rootRef}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} showLoadingIndicator/>
            <StyledSearchResult
              show={query && query.length > 0 && hasFocus}
              indices={indices}
            />
          </InstantSearch>
        </StyledSearchRoot>
      </ThemeProvider>
    )
  }