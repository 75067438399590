import React from 'react';
import { motion, useViewportScroll, useTransform } from "framer-motion"

const ParallaxTitle = ({ children }) => {
    
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, [0, 300], [0, 200]);


    return (
            <motion.div style={{ y: y1, x: 0 }} >
                {children}
            </motion.div>
    )
}

export default ParallaxTitle;