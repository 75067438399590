import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const NewsletterIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="43.276" height="28.851" viewBox="0 0 43.276 28.851" {...props}>
  <path id="Path_1143" data-name="Path 1143" d="M160.364,326.887H125.5a1.8,1.8,0,0,0-1.8,1.8v.6h-3.006a1.8,1.8,0,0,0-1.8,1.8v20.436a4.208,4.208,0,0,0,4.208,4.206H158.56a3.606,3.606,0,0,0,3.606-3.606V328.69a1.8,1.8,0,0,0-1.8-1.8M123.1,352.131a.6.6,0,0,1-.6-.6V332.9h1.2v18.633a.6.6,0,0,1-.6.6m17.732-1.2H129.41a.9.9,0,0,1-.9-.9v-.6a.9.9,0,0,1,.9-.9H140.83a.9.9,0,0,1,.9.9v.6a.9.9,0,0,1-.9.9m15.627,0h-11.42a.9.9,0,0,1-.9-.9v-.6a.9.9,0,0,1,.9-.9h11.42a.9.9,0,0,1,.9.9v.6a.9.9,0,0,1-.9.9m-15.627-7.213H129.41a.9.9,0,0,1-.9-.9v-.6a.9.9,0,0,1,.9-.9H140.83a.9.9,0,0,1,.9.9v.6c0,.5-.9.9-.9.9m15.627,0h-11.42a.9.9,0,0,1-.9-.9v-.6a.9.9,0,0,1,.9-.9h11.42a.9.9,0,0,1,.9.9v.6a.9.9,0,0,1-.9.9m0-7.213H129.41a.9.9,0,0,1-.9-.9v-3a.9.9,0,0,1,.9-.9h27.047a.9.9,0,0,1,.9.9v3a.9.9,0,0,1-.9.9" transform="translate(-118.891 -326.887)" fill="#fff"/>
</Logo>
  ) 
}

export default NewsletterIcon;
