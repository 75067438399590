import React from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Heading,
  Link,
  GridItem,
} from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import styled from "@emotion/styled";

const GridContainer = styled(Box)`
  column-count: 3;
  column-gap: 3rem;
  @media (max-width: 48em) {
    column-count: 1;
   }
`;

const Item = styled(GridItem)`
  margin: 0;
  display: grid;
  grid-template-rows: 3fr auto;
  margin-bottom: 1rem;
  break-inside: avoid;
  @media (max-width: 48em) {
    grid-template-columns: 1fr;
   }
`;

const CommunityMenu = ({ sticky }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicMainNavigation(filter: {tags: {eq: "Community"}}) {
        edges {
          node {
            data {
              title {
                text
              }
              column {
                menu_group {
                  document {
                    ... on PrismicNavigationMenu {
                      data {
                        body {
                          ... on PrismicNavigationMenuDataBodyMenuItem {
                            id
                            slice_type
                            primary {
                              heading_link {
                                url
                                uid
                              }
                              heading {
                                text
                              }
                            }
                            items {
                              link_label {
                                text
                              }
                              link {
                                url
                                target
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicMainNavigation.edges[0].node.data.column;

  const menuGroup = document.map((item, index) => {
    return (
      item
    )
  });

  //console.log(menuGroup[0].menu_group.document)

  return (
    <Flex justifyContent={{base: 'flex-start', md: 'flex-end'}} alignItems="center">
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={
                isOpen ? (
                  <ChevronUpIcon w={4} h={4} />
                ) : (
                  <ChevronDownIcon w={4} h={4} />
                )
              }
              isActive={isOpen}
              activeClassName="active"
              variant="ghost"
              textTransform="uppercase"
              fontSize="1.5rem"
              fontWeight="bold"
              fontFamily="Geared Slab"
              _hover={{ background: "none", color: "#FCBA04" }}
              _active={{ background: "none" }}
              zIndex="100"
              textShadow={!sticky ? (`1px 1px 4px #00000075`) : (`none`)}
            >
              Community
            </MenuButton>
            <MenuList
              p={{ base: 6, lg: 4 }}
              // className="how-do-i-menu"
              marginTop={{ base: "5%", lg: "21px" }}
            >
              <GridContainer> 
                    {menuGroup.map((item) => {
                      return (
                        <>
                          {item.menu_group.document.data.body.map((menuItem, idx) => {
                            return (
                             <Item key={idx} height="min-content">
                               {menuItem.primary.heading_link.url !== null 
                                ? (<Link to={menuItem.primary.heading_link.url} as={GatsbyLink}>
                                    <Heading  as="h6" fontSize="1.3rem" textTransform="capitalize" color="white" whiteSpace="nowrap" _hover={{color: 'happy-yellow.500'}}>
                                      {menuItem.primary.heading.text}
                                    </Heading>
                                    </Link>) 
                                : ( <Heading  as="h6" fontSize="1.3rem" textTransform="uppercase" color="happy-yellow.500" whiteSpace="nowrap"  borderBottom="1px solid #fcba0470">
                                {menuItem.primary.heading.text}
                              </Heading> )
                                }
                                
                                {menuItem.items.map((item, index_) => {
                                  return (
                                   <>
                                     {item.link_label.text && (
                                      <Box key={index_}>
                                       <>
                                       {/* This method breaks keyboard accessiblity since it renders a button with a link */}
                                       {item.link_label.text && (
                                        <>
                                          {item.link.target === "_blank" 
                                            ? (<MenuItem key={index_}
                                              className="sub-menu-item"
                                              whiteSpace="nowrap"
                                              padding="0" 
                                              as="a" 
                                              href={item.link.url} 
                                              target="_blank" 
                                              rel="noopener noreferrer"
                                              activeClassName="active"
                                              ispartiallyActive={true}
                                              variant="menu-link"
                                              >
                                                {item.link_label.text}</MenuItem>
                                              ) 
                                            : (<MenuItem key={index_}
                                                className="sub-menu-item"
                                                whiteSpace="nowrap"
                                                padding="0"
                                                as={GatsbyLink}
                                                to={item.link.url}
                                                as={GatsbyLink}
                                                target={item.link.target}
                                                activeClassName="active"
                                                ispartiallyActive={true}
                                                variant="menu-link">
                                                  {item.link_label.text}
                                              </MenuItem>
                                              )}
                                        </>
                                   )} 
                                   </> 
                                  </Box>
                                   
                                   )} 
                                   </> 

                                  )
                                })}
                                
                             </Item>
                            )
                          })}
                        </>
                      )
                    }
                    )}
                 </GridContainer>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default CommunityMenu;
