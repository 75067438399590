// Required for Prismic 
// Remember that the routes are available in the url field, not the uid field in the query

exports.linkResolver = (doc) => {
  
    if (doc.type === 'homepage') {
      return `/`
    }
    
    if (doc.type === 'page') {
      return `/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid !== 'code-enforcement' && doc.uid !== 'job-application' && doc.uid !== 'city-center-reservation-form' && doc.uid !== 'banner-application' && doc.uid !== 'special-event-permit-application' && doc.uid !== 'bulk-water-permit-application-per-company' && doc.uid !== 'existing-water-sewer-application' && doc.uid !== 'new-water-sewer-application' && doc.uid !== 'banner-application' && doc.uid !== 'city-center-reservation-form' && doc.uid !== 'special-event-permit-application' && doc.uid !== 'park-reservation-form' && doc.uid !== 'park-calendar') {
      return `/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'code-enforcement') {
      return `/departments/code-enforcement`
    }

    if (doc.type === 'static_page' && doc.uid === 'job-application') {
      return `/departments/human-resources/job-application`
    }

    if (doc.type === 'static_page' && doc.uid === 'city-center-reservation-form') {
      return `/departments/facilities-events/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'park-reservation-form') {
      return `/departments/facilities-events/${doc.uid}`
    }

    if (doc.type == 'static_page' && doc.uid === 'park-calendar') {
      return `/departments/facilities-events/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'banner-application') {
      return `/departments/facilities-events/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'special-event-permit-application') {
      return `/departments/facilities-events/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'bulk-water-permit-application-per-company') {
      return `/departments/utilities/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'existing-water-sewer-application') {
      return `/departments/utilities/${doc.uid}`
    }

    if (doc.type === 'static_page' && doc.uid === 'new-water-sewer-application') {
      return `/departments/utilities/${doc.uid}`
    }

    if (doc.type === 'news') {
      return `/news/${doc.uid}`
    }

    if (doc.type === 'department_page') {
      return `/departments/${doc.uid}`
    }

    if (doc.type === 'board_page') {
      return `/boards-commissions-committees/${doc.uid}`
    }

    if (doc.type === 'city_clerk_page') {
      return `/departments/city-clerk/${doc.uid}`
    }

    if (doc.type === 'city_clerk_form') {
      return `/departments/city-clerk/forms/${doc.uid}`
    }

    // if (doc.type === 'joint_housing_authority_page') {
    //   return `/departments/joint-housing-authority-board/${doc.uid}`
    // }

    if (doc.type === 'community_development_page') {
      return `/departments/community-and-economic-development/${doc.uid}`
    }

    if (doc.type === 'public_works_page') {
      return `/departments/public-works/${doc.uid}`
    }

    if (doc.type === 'planning_and_building_page') {
      return `/departments/planning-and-building/${doc.uid}`

    }

    if (doc.type === 'human_resources_page') {
      return `/departments/human-resources/${doc.uid}`
    }

    if (doc.type === 'about_page') {
      return `/about/${doc.uid}`
    }

    if (doc.type === 'bid_rfp_project_page') {
      return `/${doc.uid}`
    }

    if (doc.type === "airport_board_page") {
      return `/boards-commissions-committees/airport-board/${doc.uid}`
    }

    if (doc.type === "city_council_page") {
      return `/boards-commissions-committees/city-council/${doc.uid}`
    }

    if (doc.type === "urban_renewal_page") {
      return `/boards-commissions-committees/driggs-urban-renewal-agency/${doc.uid}`
    }

    if (doc.type === "parks_and_recreation_page") {
      return `/departments/parks-and-recreation/${doc.uid}`
    }

    if (doc.type === "parks_and_recreation_board_page") {
      return `/boards-commissions-committees/parks-recreation-committee/${doc.uid}`
    }

    if (doc.type === "design_review_advisory_committee_page") {
      return `/boards-commissions-committees/driggs-design-review-advisory-committee/${doc.uid}`
    } 

    if (doc.type === "planning_and_zoning_board_page") {
      return `/boards-commissions-committees/planning-zoning-committee/${doc.uid}`
    }

    if (doc.type === 'tree_and_beautification_page') {
      return `/boards-commissions-committees/tree-beautification-committee/${doc.uid}`
    }

    if (doc.type === 'joint_housing_authority_page') {
      return `/boards-commissions-committees/joint-housing-authority-board/${doc.uid}`
    }

    if (doc.type === 'projects_subpage') {
      return `/projects/${doc.uid}`
    }
  
    if (doc.type === "calendar_page") {
      return `/calendar`
    }

    return '/'
  }
  