import React from 'react';

const ChooseLanguage = () => {


    return (

<div id="google_translate_element"></div>
    
  )
}
export default ChooseLanguage;