import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const CalendarIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="32.3" height="36.915" viewBox="0 0 32.3 36.915" {...props}>
  <path id="Path_1146" data-name="Path 1146" d="M315.141,350.34a3.461,3.461,0,0,0,3.46,3.461H343.98a3.462,3.462,0,0,0,3.461-3.461V330.73h-32.3Zm23.072-14.131a.867.867,0,0,1,.865-.865h2.883a.868.868,0,0,1,.866.865v2.884a.868.868,0,0,1-.866.865h-2.883a.867.867,0,0,1-.865-.865Zm0,9.229a.867.867,0,0,1,.865-.865h2.883a.868.868,0,0,1,.866.865v2.884a.868.868,0,0,1-.866.865h-2.883a.867.867,0,0,1-.865-.865Zm-9.23-9.229a.868.868,0,0,1,.866-.865h2.883a.867.867,0,0,1,.865.865v2.884a.867.867,0,0,1-.865.865h-2.883a.868.868,0,0,1-.866-.865Zm0,9.229a.868.868,0,0,1,.866-.865h2.883a.867.867,0,0,1,.865.865v2.884a.867.867,0,0,1-.865.865h-2.883a.868.868,0,0,1-.866-.865Zm-9.227-9.229a.867.867,0,0,1,.865-.865H323.5a.867.867,0,0,1,.865.865v2.884a.867.867,0,0,1-.865.865H320.62a.867.867,0,0,1-.865-.865Zm0,9.229a.867.867,0,0,1,.865-.865H323.5a.867.867,0,0,1,.865.865v2.884a.867.867,0,0,1-.865.865H320.62a.867.867,0,0,1-.865-.865ZM343.98,321.5h-3.461V318.04a1.156,1.156,0,0,0-1.153-1.153h-2.307a1.156,1.156,0,0,0-1.153,1.153V321.5h-9.23V318.04a1.156,1.156,0,0,0-1.153-1.153h-2.307a1.156,1.156,0,0,0-1.153,1.153V321.5H318.6a3.461,3.461,0,0,0-3.46,3.461v3.46h32.3v-3.46a3.462,3.462,0,0,0-3.461-3.461" transform="translate(-315.141 -316.887)" fill="#fff"/>
</Logo>
  )
}

export default CalendarIcon;
