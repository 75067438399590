import React, { useEffect } from 'react';
import {
    Box,
    IconButton,
    Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Tooltip
} from '@chakra-ui/react'
import TranslateIcon from './Icons/TranslateIcon';

const GoogleTranslate = () => {

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
       }

       useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, [])
    
    return (
      <>
      <Tooltip label="Translate" hasArrow>
        <Box zIndex="1001">
        
          <Popover>
              
              <PopoverTrigger>
              
                <IconButton
                  aria-label="Info"
                  icon={<TranslateIcon />}
                  fontSize="1.5rem"
                  variant="ghost"
                  color="white"
                  _hover={{ color: "happy-yellow.500" }}
                />
                
              </PopoverTrigger>
            
            <PopoverContent>
      <PopoverArrow />
      <PopoverHeader>Google Translate</PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody>
                <div id="google_translate_element"></div>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
        </Tooltip>
        {/* <IconButton aria-label="Info" icon={<FaInfoCircle />} fontSize="1.5rem" variant="ghost" color="white" _hover={{color: 'happy-yellow.500'}} onClick={onOpen}/>

<Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Google Translate</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
        <div id="google_translate_element"></div>
    </ModalBody>
  </ModalContent>
</Modal> */}
      </>
    );
}

export default GoogleTranslate;