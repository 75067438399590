import React from 'react'
import {
    Flex,
} from '@chakra-ui/react'
import HowDoIMenu from './NavMenus/HowDoIMenu'
import CommunityMenu from './NavMenus/CommunityMenu'
import InsideCityHallMenu from './NavMenus/InsideCityHallMenu'


const NavMenu = () => {
    return (
            <Flex justifyContent="space-between" w="100%" alignItems="center">
                    <HowDoIMenu />
                    <InsideCityHallMenu  />
                    <CommunityMenu />
            </Flex>
    )
}

export default NavMenu