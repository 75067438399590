import React from 'react'
import {
    Icon
} from '@chakra-ui/react'

const AccessibilityIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props} width="1em" height="1em">
  <path fill="currentColor" id="Path_28" data-name="Path 28" d="M94.347,186.4a12.581,12.581,0,1,1-12.581,12.581A12.574,12.574,0,0,1,94.347,186.4m0-2.419a15,15,0,1,0,15,15,15,15,0,0,0-15-15m0,3.387a11.613,11.613,0,1,0,11.612,11.613A11.613,11.613,0,0,0,94.347,187.37m0,2.661a2.177,2.177,0,1,1-2.178,2.178,2.178,2.178,0,0,1,2.178-2.178m7.121,5.929a43.332,43.332,0,0,1-4.968.956c.051,6.11.744,7.443,1.514,9.413a1.089,1.089,0,0,1-2.028.792,17.344,17.344,0,0,1-1.346-4.751h-.586a17.369,17.369,0,0,1-1.347,4.751,1.089,1.089,0,0,1-2.028-.792c.769-1.969,1.463-3.3,1.514-9.413a43.332,43.332,0,0,1-4.968-.956.968.968,0,0,1,.445-1.884c5.848,1.381,7.517,1.378,13.353,0a.968.968,0,1,1,.445,1.884" transform="translate(-79.347 -183.983)" />
</Icon>
)

export default AccessibilityIcon



