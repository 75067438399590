import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '24px' };
  height: auto;
`


const SearchIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.995 30" {...props}>
  <path id="Path_29" data-name="Path 29" d="M184.531,209.92l-5.842-5.841a1.4,1.4,0,0,0-1-.41h-.955a12.181,12.181,0,1,0-2.109,2.109v.955a1.406,1.406,0,0,0,.41,1l5.842,5.841a1.4,1.4,0,0,0,1.986,0l1.658-1.658a1.412,1.412,0,0,0,.006-1.992m-17.4-6.251a7.5,7.5,0,1,1,7.5-7.5,7.495,7.495,0,0,1-7.5,7.5" transform="translate(-154.944 -183.983)" fill={props.fill} />
</Logo>
  );
};

export default SearchIcon;

