import React from 'react';
import {
    Box,
    Flex,
    IconButton,
    Link,
    Tooltip
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { FaFacebookSquare } from "react-icons/fa";
import UserIcon from "./Icons/UserIcon";
import AirplaneIcon from "./Icons/AirplaneIcon";
import AccessibilityIcon from './Icons/Accessibility';

import GoogleTranslate from './GoogleTranslate';
import Search from "../Search/Search"

const searchIndices = [
    { name: `News`, title: `News`, type: `newsHit` },
    { name: `About Pages`, title: `About Pages`, type: `aboutHit` },
    { name: `Airport Board Pages`, title: `Airport Pages`, type: `airportHit` },
    { name: `Bids RFPs Projects`, title: `Bids, RFPs &amp; Projects`, type: `airportBoardHit` },
    { name: `Pages`, title: `Pages`, type: `pageHit` },
    { name: `Board Pages`, title: `Board Pages`, type: `boardPagesHit` },
    { name: `City Clerk Forms`, title: `City Clerk Forms`, type: `cityClerkHit` },
    { name: `City Clerk Pages`, title: `City Clerk Pages`, type: `cityClerkPagesHit` },
    { name: `City Council Pages`, title: `City Council Pages`, type: `cityCouncilHit` },
    { name: `Community Development Pages`, title: `Community Development Pages`, type: `communityDevelopmentHit` },
    { name: `Departments`, title: `Departments`, type: `departmentHit` },
    { name: `Design Review Adivsory Committee Pages`, title: `Design Review Advisory Committee Pages`, type: `designReviewAdvisoryCommitteeHit` },
    { name: `Parks Recreation Board Pages`, title: `Parks &amp; Recreation Board Page`, type: `parksAndRecreationHit` },
    { name: `Parks Recreation Pages`, title: `Parks &amp; Recreation Pages`, type: `parksAndRecreationPagesHit` },
    { name: `Planning Building Pages`, title: `Planning &amp; Building Pages`, type: `planningBuildingHit` },
    { name: `Planning Zoning Board Pages`, title: `Planning &amp; Zoning Board Pages`, type: `planningZoningHit` },
    { name: `Projects`, title: `Projects`, type: `projectHit` },
    { name: `Public Works Pages`, title: `Public Works Pages`, type: `publicWorksHit` },
    { name: `Tree Beautification Pages`, title: `Tree &amp; Beautification Pages`, type: `treeBeautificationHit` },
    { name: `Urban Renewal Pages`, title: `Urban Renewal Pages`, type: `urbanRenewalHit` },

]

const IconBar = () => {
    return (
       <>
           <Box p="1" mr="4">
            <Flex flexDirection="row" justifyContent="flex-end">
                <Tooltip label="Follow On Facebook" hasArrow>
                    <Link href="https://www.facebook.com/DriggsIdaho" target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="Facebook" icon={<FaFacebookSquare />} fontSize="1.5rem" variant="ghost" color="white" _hover={{color: 'happy-yellow.500'}}/>
                    </Link>
                </Tooltip>
                <Tooltip label="Airport Website" hasArrow>
                <Link href="https://driggsairport.org" target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="Airport" icon={<AirplaneIcon />} fontSize="1.5rem" variant="ghost" color="white" _hover={{color: 'happy-yellow.500'}}/>
                </Link>
                </Tooltip>
                <Tooltip label="Contact" hasArrow>
                <Link to="/contact" as={GatsbyLink}>
                    <IconButton aria-label="User" icon={<UserIcon />} fontSize="1.5rem" variant="ghost" color="white" _hover={{color: 'happy-yellow.500'}}/>
                </Link>
                </Tooltip>
                <GoogleTranslate />
                <Tooltip label="Accessiblity Tools" hasArrow>
                    <IconButton aria-label="User" icon={<AccessibilityIcon />} fontSize="1.625rem" variant="ghost" color="white" _hover={{color: 'happy-yellow.500'}} id="accessibilityWidget" tabindex="0" aria-label="Accessibility Widget" ml="0.25rem"/></Tooltip>
                <Search indices={searchIndices} />
                    
                
            </Flex>
        </Box>
       </>
    )
}



export default IconBar;