import React from "react";
import {
  Icon
} from '@chakra-ui/react'

const TranslateIcon = (props) => (

<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 25" width="2em" height="2em" {...props} ><g id="Layer_2" data-name="Layer 2" ><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2"><g id="Layer_1-2-2" data-name="Layer 1-2"><path fill="currentColor" class="cls-1" d="M11.88,11c-.27-.94-.61-2.59-.61-2.59h0S10.9,10,10.62,11l-.86,2.93h3ZM48.12,0H26.25V25H48.12A1.88,1.88,0,0,0,50,23.12h0V1.88A1.88,1.88,0,0,0,48.12,0ZM46.25,9.38a.94.94,0,0,1-.94.93h-.89a13.9,13.9,0,0,1-3.33,5.46,23.69,23.69,0,0,0,2,1.41,1,1,0,0,1,.33,1.27l-.62,1.08a1,1,0,0,1-1.31.34,27.13869,27.13869,0,0,1-2.76-1.95A27.77082,27.77082,0,0,1,36,19.87a.94.94,0,0,1-1.28968-.32237L34.7,19.53l-.62-1.08a.94.94,0,0,1,.33-1.27,23.68221,23.68221,0,0,0,2-1.41,16.531,16.531,0,0,1-1.64-2,.93.93,0,0,1,.22219-1.29631q.03294-.02331.06782-.04369l.5-.31.57-.33a1,1,0,0,1,1.25.26,15.3299,15.3299,0,0,0,1.36,1.64,11.99989,11.99989,0,0,0,2.35-3.38h-8.9a.94.94,0,0,1-.94-.94h0V8.12a.94.94,0,0,1,.94-.93h5V5.94A.94.94,0,0,1,38.12,5h1.26a.94.94,0,0,1,.93.94V7.19h5a.94.94,0,0,1,.94.93ZM0,1.88V23.12A1.88,1.88,0,0,0,1.88,25H23.75V0H1.88A1.88,1.88,0,0,0,0,1.88ZM4.6,18.76,9.09,5.55A.94.94,0,0,1,10,4.91h2.54a1,1,0,0,1,.89.64l4.5,13.21A.94.94,0,0,1,17,20H15.23a.93.93,0,0,1-.9-.67l-.74-2.49H8.89l-.71,2.48a.94.94,0,0,1-.9.68H5.49a.94.94,0,0,1-.89-1.24Z"/></g></g></g></g>

</Icon>
  );

export default TranslateIcon;


