import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

const IFrame = ({ slice }) => {
    return (
        <Box mb="16"
            dangerouslySetInnerHTML={{ __html: slice.primary.iframe_source.text }}
        ></Box>
        // <Box mb="16">
        //     <iframe src={slice.primary.iframe_source.text} width="100%" height="500px">Loading Map...</iframe>
        // </Box>
    )
}

export default IFrame;