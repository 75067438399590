import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
    Box,
    Text
} from '@chakra-ui/react'

const AlignedImage = ({ slice }) => {

    console.log(slice)
    return (
        <Box width="30%" float={slice.primary.align === 'Right' ? 'right' : 'left'} marginLeft={slice.primary.align === 'Right' ? '1rem' : 'none'} marginRight={slice.primary.align === 'Right' ? 'none' : '1rem' } >
            <GatsbyImage image={getImage(slice.primary.image.localFile)} alt={slice.primary.image.alt} imgStyle={{borderRadius: '0.5rem'}}/>
        </Box>
    )
}

export default AlignedImage;