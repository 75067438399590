import React from "react";
import { Box, MenuItem, Heading, Link, Flex } from "@chakra-ui/react";

import { Link as GatsbyLink } from "gatsby";

const SubNavItem = ({ slice }) => {

    return (
        <>
        <Flex w={{base: '100%', lg: '33%'}} mr="4" flexDirection="column">
        {slice.primary.column === "1" && (
             <>
               {slice.items.map((item, index) => {
                 return (
                    <Box>
                        {item.heading.text && (
                            <Heading as="h6" fontSize="1.3rem" textTransform="uppercase" color="happy-yellow.500" marginTop="1rem" whiteSpace="nowrap">{item.heading.text}</Heading>
                        )}
                   <Box key={index}>
                       <MenuItem className="sub-menu-item" whiteSpace="nowrap" padding="0">
                       <Link to={item.link.url} variant="submenu-item" as={GatsbyLink} target={item.link.target}>
                       {item.link_label.text}
                       </Link>
                       </MenuItem>
                   </Box>
                   </Box>
                 );
               })}
         </>
        )}
        </Flex>
        <Flex w={{base: '100%', lg: '33%'}} mr="4" flexDirection="column">
        {slice.primary.column === "2" && (
             <>
               {slice.items.map((item, index) => {
                 return (
                    <Box>
                        {item.heading.text && (
                            <Heading as="h6" fontSize="1.3rem" textTransform="uppercase" color="happy-yellow.500" marginTop="1rem" whiteSpace="nowrap">{item.heading.text}</Heading>
                        )}
                   <Box key={index}>
                       <MenuItem className="sub-menu-item" whiteSpace="nowrap" padding="0">
                       <Link to={item.link.url} variant="submenu-item" as={GatsbyLink} target={item.link.target}>
                       {item.link_label.text}
                       </Link>
                       </MenuItem>
                   </Box>
                   </Box>
                 );
               })}
         </>
        )}
        </Flex>
        <Flex w={{base: '100%', lg: '33%'}} mr="2" flexDirection="column">
        {slice.primary.column === "3" && (
             <>
               {slice.items.map((item, index) => {
                 return (
                    <Box>
                        {item.heading.text && (
                            <Heading as="h6" fontSize="1.3rem" textTransform="uppercase" color="happy-yellow.500" marginTop="1rem" whiteSpace="nowrap">{item.heading.text}</Heading>
                        )}
                   <Box key={index}>
                       <MenuItem className="sub-menu-item" whiteSpace="nowrap" padding="0">
                       <Link to={item.link.url} variant="submenu-item" as={GatsbyLink} target={item.link.target}>
                       {item.link_label.text}
                       </Link>
                       </MenuItem>
                   </Box>
                   </Box>
                 );
               })}
         </>
        )}
        </Flex>
    </>
    )
};

export default SubNavItem;
