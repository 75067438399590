import React from 'react'
import {
    Box,
    Flex,
    Link,
    Text,
    HStack,
    LinkOverlay,
    LinkBox,
} from '@chakra-ui/react'
import CityOfDriggsLogo from '../Header/CityOfDriggsLogo';
import { Link as GatsbyLink } from 'gatsby'

import styled from '@emotion/styled'

import PhoneIcon from './FooterIcons/PhoneIcon';
import CodeRed from './FooterIcons/CodeRedIcon';
import CalendarIcon from './FooterIcons/CalendarIcon';
import MeetingIcon from './FooterIcons/MeetingIcon';
import NewsletterIcon from './FooterIcons/NewsletterIcon';
import MayorsCommentBoxIcon from './FooterIcons/MayorsCommentBoxIcon';
import OnlineBillPayIcon from './FooterIcons/OnlineBillPayIcon';
import AirportIcon from './FooterIcons/AirportIcon';
import FacebookLogo from './FooterIcons/FacebookLogo';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import CalderaCreative from './CalderaCreative';
import PublicNoticesIcon from './FooterIcons/PublicNotices';
import AccessibilityIcon from '../Header/Icons/Accessibility';

const FooterLink = styled(LinkBox)`
    
    &:hover > div > svg > path {
        fill: #FCBA04;
    }
    &:hover > div > svg > g > g > path {
        fill: #FCBA04;
    }
    &:hover  > div > p > a {
        color: #FCBA04;
    }
`;
const FooterColumn = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    > div {
        height: 33.3%
    }
`;

const Footer = () => {
    return (
        <Box as="footer">
            <Box bg="#0C3242">
                    <Flex w="100%" justifyContent="space-between" alignItems="stretch" p="8" className="footerBox" flexWrap="wrap">
                        <Box w={{base: '100%', md: 'auto'}} >
                            <Flex flexDirection={{base: 'row', md: 'column'}} justifyContent="center" alignItems="center" mb={{base: '4', md: 0}}>
                                <Box mb="4" w={{base: '40%', md: '100%'}}>
                                    <CityOfDriggsLogo width="130px"/>
                                </Box>
                                <Box w={{base: '60%', md: '100%'}} ml={{base: '4', md: '0'}}>
                                    <Text color="white" fontFamily="Geared Slab, sans-serif">
                                        60 South Main Street<br />
                                        Post Office Box 48<br />
                                        Driggs, Idaho 83422
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                        
                        <Box w={{base: '100%', md: 'auto'}}>
                            <FooterColumn>
                                <Box>
                                    <FooterLink mb="4">
                                            <HStack>
                                                <PhoneIcon width="20px" />
                                                    <Text fontFamily="Geared Slab, sans-serif">
                                                        <LinkOverlay href="tel:208-354-2362" variant="footer-nav-link" color="white">208-354-2362</LinkOverlay>
                                                    </Text>
                                            </HStack>
                                    </FooterLink>
                                </Box>
                                <Box>
                                    <FooterLink  mb="4">
                                    <HStack>
                                        <NewsletterIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay as={GatsbyLink} to="/#newsletter" variant="footer-nav-link" color="white">Newsletter Sign Up</LinkOverlay>
                                        </Text>
                                    </HStack>
                                    </FooterLink>
                                </Box>
                                <Box>
                                    <FooterLink mb="4">
                                        <HStack>
                                            <CodeRed width="20px" />
                                                <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                                    <LinkOverlay href="https://public.coderedweb.com/CNE/en-US/C04FE3EEE28E" target="_blank" rel="noopener noreferrer" variant="footer-nav-link" color="white">Sign Up for CODE RED <ExternalLinkIcon mb="2"/></LinkOverlay>
                                                </Text>
                                        </HStack>
                                    </FooterLink>
                                </Box>
                            </FooterColumn>
                        </Box>

                        <Box w={{base: '100%', md: 'auto'}}>
                            <FooterColumn>
                                <FooterLink mb="4">
                                    <HStack>
                                        <CalendarIcon width="15px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay as={GatsbyLink} to="/calendar" variant="footer-nav-link" color="white">City Calendar</LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                        <MeetingIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                        <LinkOverlay as={GatsbyLink} to="/boards-commissions-committees" variant="footer-nav-link" color="white">Meeting Information</LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                        <AirportIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                                <LinkOverlay href="https://driggsairport.org" variant="footer-nav-link" color="white" target="_blank" rel="noopener noreferrer">Airport Website <ExternalLinkIcon mb="2"/></LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                            </FooterColumn>
                        </Box>

                        <Box w={{base: '100%', md: 'auto'}}>
                            <FooterColumn>
                                <FooterLink mb="4">
                                    <HStack>
                                        <OnlineBillPayIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay as={GatsbyLink} to="/online-payments" variant="footer-nav-link" color="white">Online Bill Pay</LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                        <NewsletterIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay href="https://us8.campaign-archive.com/home/?u=a7b3a4f7e1408df5e21094d10&id=2cd7498c58" variant="footer-nav-link" color="white">Newsletter Archive <ExternalLinkIcon mb="2"/></LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                        <MayorsCommentBoxIcon width="20px" />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay as={GatsbyLink} to="/mayors-comment-box" variant="footer-nav-link" color="white">Mayor's Comment Box</LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                            </FooterColumn>
                        </Box>
                        
                        <Box w={{base: '100%', md: 'auto'}}>
                            <FooterColumn>
                                <FooterLink mb="4">
                                <HStack>
                                    <PublicNoticesIcon />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay as={GatsbyLink} to="/public-notices" variant="footer-nav-link" color="white">Public Notices</LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                    <FacebookLogo />
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay href="https://www.facebook.com/DriggsIdaho" target="_blank" rel="noopener noreferrer" variant="footer-nav-link" color="white">Follow Us <ExternalLinkIcon mb="2"/></LinkOverlay>
                                        </Text>
                                    </HStack>
                                </FooterLink>
                                <FooterLink mb="4">
                                    <HStack>
                                    <AccessibilityIcon color="white"/>
                                        <Text color="white" fontFamily="Geared Slab, sans-serif" variant="footer-nav-link">
                                            <LinkOverlay id="accessibilityWidget" tabindex="0" aria-label="Accessibility Widget" variant="footer-nav-link" color="white" style={{cursor: 'pointer'}}>Accessiblity Tools</LinkOverlay>
                                        </Text>
                                        
                                    </HStack>
                                </FooterLink>
                            </FooterColumn>
                        </Box>

                    </Flex>
                    <Flex w="100%" justifyContent={{base: "center", md: "flex-start"}} alignItems="center" px="8" bg="elephant.400" py="2" flexWrap="wrap">
                        <Box mr={{base: 0, md: 8}} w={{base: '100%', md: 'auto'}}>
                            <Text color="white" mb="0" variant="sub-footer-nav-link">
                                <Link as={GatsbyLink} to="/" variant="sub-footer-nav-link">Homepage</Link>
                            </Text>
                        </Box>
                        <Box  mr={{base: 0, md: 8}} w={{base: '100%', md: 'auto'}}>
                            <Text color="white" mb="0" variant="sub-footer-nav-link">
                                <Link href="" variant="sub-footer-nav-link">Sitemap</Link>
                            </Text>
                        </Box>
                        <Box  mr={{base: 0, md: 8}} w={{base: '100%', md: 'auto'}}>
                            <Text color="white" mb="0" variant="sub-footer-nav-link">
                                <Link as={GatsbyLink} to="/privacy-policy" variant="sub-footer-nav-link">Privacy Policy</Link>
                            </Text>
                        </Box>
                        <Box marginLeft="auto" w={{base: '100%', md: 'auto'}}>
                            <CalderaCreative />
                        </Box>
                    </Flex>
            </Box>
        </Box>
    )
}

export default Footer;