import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Link
} from '@chakra-ui/react'
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { linkResolver } from './linkResolver';

export const CustomLink = ({ node, children }) => {
  return (
    <>
      {node.data && node.data.link_type === 'Document' ? (
        <Link as={GatsbyLink} to={linkResolver(node?.data)} key={node.data?.id} textDecoration="underline">
          {children}
        </Link>
      ) : node.data && node.data.link_type === 'Media' ? (
        <a href={node.data?.url} target="_blank" rel="noopener noreferrer">
          <strong>{children}
          {node.data?.url.includes('.pdf' || '.PDF') && <span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>}</strong>
        </a>
      ) : node.data && node.data.link_type === 'Web' ? (
        <Link href={node.data?.url} target="_blank" rel="noopener noreferrer">
          {children}
          {node.data?.url.includes('.pdf' || '.PDF') 
            ? (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>) 
            : (node.data?.url.includes('municode'))
            ? (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>) 
            : (<span>{' '}<ExternalLinkIcon style={{display: 'inline-block', verticalAlign: 'middle', marginBottom: '0.25rem'}} /></span>)}
        </Link>
      ) : null}
    </>
  )
}