import React from 'react'
import { BgImage } from 'gbimage-bridge'
import {
  Box,
  Heading,
  Flex,
  Text,
} from '@chakra-ui/react'
import Tetons from './Tetons'
import ParallaxTitle from './ParallaxTitle'
import styled from '@emotion/styled'


const PageHero = (props) => {
    // Explicitly delcare props and destructure the other properties:
  const { className, children, ...rest } = props;

  return (
    <Box display={{base: 'none', lg: 'block'}}>
    <BgImage
        tag="section"
        className={className}
        image={props.heroImage}
    >
       <Flex w="100%" height="60vh" flexDirection="column" justifyContent="flex-end" paddingBottom="5%" alignItems="left">
    <ParallaxTitle>
      <Box px="4" zIndex="1">
        <Heading color="white" as="h1" fontSize={{base: "2rem", lg: "4rem"}} textTransform="uppercase" textShadow="1px 1px 5px #00000050;">
          {props.pageTitle}
        </Heading>
      </Box>
    </ParallaxTitle>
  </Flex>
  {props.photoCredit && (
    <Box position="absolute" bottom="0" left="2">
    <Text color="white" textTransform="uppercase" fontWeight="bold" zIndex="100" mb="0" textShadow="0px 0px 2px #0000007a;">
      Photo: {props.photoCredit}
    </Text>
  </Box>
  )}
  
  <Tetons 
    boxWidth="45%"
  />
        
    </BgImage>
    
    </Box>
  )
}

const StyledBackgroundSection = styled(PageHero)`
    width: 100%;
    min-height: 60vh;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
`;

export default StyledBackgroundSection