import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const FacebookLogo = (props) => {
  return (

<Logo id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" {...props}>
  <path d="M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H185.25V327.69h-63V256h63V201.36c0-62.15,37-96.48,93.67-96.48,27.14,0,55.52,4.84,55.52,4.84v61H303.17c-30.81,0-40.42,19.12-40.42,38.73V256h68.78l-11,71.69H262.75V480H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32Z" transform="translate(0 -32)" class="cls-1" />
</Logo>
  )
}

export default FacebookLogo;
