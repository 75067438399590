import React from 'react';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Heading,
    Text
} from '@chakra-ui/react';
import { PrismicRichText } from '@prismicio/react';
import { CustomLink } from '../../utils/customLink'

const BidAccordion = ({ slice }) => {
    return (
        <Box className="bid-accordion" mb="12">
            <Box mb="4">
                <Heading as="h4" textTransform="uppercase" fontSize="1.5rem">
                    {slice.primary.accordion_title.text}
                </Heading>
                <Text mb="4">
                    {slice.primary.accordion_subtitle?.text}
                </Text>
            </Box>
        
        <Accordion allowMultiple>
            {slice.items.map((item, index) => {
                return (
                  <AccordionItem key={index}>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading
                          as="h5"
                          textTransform="uppercase"
                          fontSize="1.25rem"
                          fontFamily="proxima-nova, sans-serif"
                        >
                          {item.item_title.text}
                        </Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Box>
                            <PrismicRichText 
    field={item.item_description.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }} />
                        </Box>
                        <div dangerouslySetInnerHTML={{__html: item.table?.text}} />
                    </AccordionPanel>
                  </AccordionItem>
                );
            })}
        </Accordion>
        </Box>
    )
}

export default BidAccordion;