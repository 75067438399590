import React from "react";
import {
    Box,
} from "@chakra-ui/react";
import { PrismicRichText } from '@prismicio/react';
import { CustomLink } from '../../utils/customLink'

const RichTextSlice = ({ slice }) => {
    console.log(slice.primary.content.richText)
    return (
        <Box my="8">
            <PrismicRichText 
    field={slice.primary.content.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }} />
        </Box>
    );
}

export default RichTextSlice;