import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const AirportIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="42.165" height="37.48" viewBox="0 0 42.165 37.48" {...props}>
  <path id="Path_1142" data-name="Path 1142" d="M194.028,334.276h-8.367l-7.694-13.464a1.169,1.169,0,0,0-1.018-.59h-4.794a1.171,1.171,0,0,0-1.126,1.493l3.59,12.562h-7.531l-3.162-4.216a1.168,1.168,0,0,0-.936-.468h-2.928a1.172,1.172,0,0,0-1.137,1.455l2.308,7.914-2.308,7.916a1.172,1.172,0,0,0,1.137,1.455h2.928a1.168,1.168,0,0,0,.936-.468l3.162-4.216h7.531l-3.59,12.561a1.171,1.171,0,0,0,1.126,1.494h4.794a1.175,1.175,0,0,0,1.018-.59l7.694-13.464h8.367c2.587,0,7.027-2.1,7.027-4.686s-4.44-4.684-7.027-4.684" transform="translate(-158.891 -320.221)" fill="#fff"/>
</Logo>

  );
} 

export default AirportIcon;
