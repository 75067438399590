import React from 'react'
import BidAccordion from '../Slices/BidAccordion'
import ContactCard from '../Slices/ContactCard'
import FormLinkModule from '../Slices/FormLinkModule'
import IFrame from '../Slices/IFrame'
import MeetingTabs from '../Slices/MeetingTabs'
import PersonCard from '../Slices/PersonCard'
import RawHtmlSlice from '../Slices/RawHtmlSlice'
import RichTextSlice from '../Slices/RichTextSlice'
import SubNavItem from '../Slices/SubNavItem'
import PhotoGallery from '../Slices/PhotoGallery'
import AlignedImage from '../Slices/AlignedImage'

// passing in the location prop from Resources.js so we can automatically select tabs on that page based on the url
const SliceZone = ({ sliceZone, location}) => {
    const sliceComponents = {
        sub_nav_item: SubNavItem,
        contact_card: ContactCard,
        form_link_module: FormLinkModule,
        bid__rfp__project_accordion: BidAccordion,
        iframe_slice: IFrame,
        raw_html_slice: RawHtmlSlice,
        rich_text: RichTextSlice,
        meeting_tabs: MeetingTabs,
        board_person_card: PersonCard,
        photo_gallery: PhotoGallery,
        aligned_photo: AlignedImage,
    }

    const pageLocation = location
    //console.log(`location: ${pageLocation}`)

    const sliceZoneContent = sliceZone.map((slice, index, location ) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          
          return <SliceComponent slice={slice} key={`slice-${index}`} location={pageLocation} />
        }
        return null
      })
    
      return <div>{sliceZoneContent}</div>
    }

export default SliceZone;