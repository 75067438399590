import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_1147:hover { 
      fill: #FCBA04
  }`

const CodeRed = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="32.584" height="28.964" viewBox="0 0 32.584 28.964" {...props}>
  <path id="Path_1149" data-name="Path 1149" d="M32.584,13.577a3.6,3.6,0,0,0-1.811-3.117V1.812A1.828,1.828,0,0,0,28.964,0a1.806,1.806,0,0,0-1.131.4l-4.81,3.848a13.73,13.73,0,0,1-8.541,3H3.62A3.62,3.62,0,0,0,0,10.861v5.431a3.62,3.62,0,0,0,3.62,3.621H5.527A13.634,13.634,0,0,0,5.4,21.723,14.333,14.333,0,0,0,6.849,28a1.778,1.778,0,0,0,1.606.964h4.2a1.8,1.8,0,0,0,1.465-2.86,7.207,7.207,0,0,1-1.479-4.381,6.853,6.853,0,0,1,.25-1.809h1.588a13.723,13.723,0,0,1,8.541,3l4.81,3.848a1.81,1.81,0,0,0,2.94-1.413V16.7a3.605,3.605,0,0,0,1.811-3.119m-5.431,8-1.869-1.5a17.359,17.359,0,0,0-10.8-3.79V10.861a17.358,17.358,0,0,0,10.8-3.788l1.869-1.5Z" transform="translate(0)" fill="#fff"/>
</Logo>
  )
}

export default CodeRed;

