import React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby'

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '30px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #Path_30:hover { 
      fill: #FCBA04
  }`


const AirplaneIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" width="30" height="26.667" viewBox="0 0 30 26.667" {...props}>
  <path id="Path_30" data-name="Path 30" d="M148.994,10h-5.953L137.567.42a.832.832,0,0,0-.724-.42h-3.411a.833.833,0,0,0-.8,1.062L135.185,10h-5.358l-2.25-3a.831.831,0,0,0-.666-.333h-2.083a.834.834,0,0,0-.809,1.035l1.642,5.631-1.642,5.632A.834.834,0,0,0,124.828,20h2.083a.831.831,0,0,0,.666-.333l2.25-3h5.358L132.631,25.6a.833.833,0,0,0,.8,1.063h3.411a.836.836,0,0,0,.724-.42l5.474-9.58h5.953c1.841,0,5-1.492,5-3.334s-3.159-3.333-5-3.333" transform="translate(-123.994)" fill="#fff"/>
</Logo>
  );
};

export default AirplaneIcon;

