import styled from '@emotion/styled';
import { css } from '@emotion/react';
import SearchResult from './SearchResult';

const Popover = css`
    max-height: 80vh;
    background-color: #fff;
    z-index: 1000;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0.5em;
    width: 80vw;
    max-width: 40em;
    box-shadow: 0 0 5px 0;
    padding: 1em;
    border-radius: 2px;
    color: #154153;
    background: ${({ theme }) => theme.background};
`

export default styled(SearchResult)`
    display: ${props => (props.show ? `block` : `none`)};
    ${Popover}

    .HitCount {
        display: flex;
        justify-content: flex-end;
    }

    .Hits {
        ul {
            list-style: none;
            margin-left: 0;
        }
        li.ais-Hits-item {
            margin-bottom: 1em;
            a {
                h4 {
                    margin-bottom: 0.2em;
                }
            }
        }
    }

    .ais-PoweredBy {
        display: flex;
        justify-content: flex-end;
        font-size: 80%;
        svg {
            width: 70px;
        }
    }

`