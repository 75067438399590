import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  &:hover {
    fill: #FCBA04
   }

  .cls-1 {
        fill: #fff;
  }
  #icon_path:hover { 
      fill: #FCBA04
  }`

const PublicNoticesIcon = (props) => {
  return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path id="icon_path" class="cls-1" d="M29.39,5.4,15.66.12a1.87,1.87,0,0,0-1.32,0L.61,5.4A.92.92,0,0,0,0,6.27V8.44a.93.93,0,0,0,.94.93H29.06A.93.93,0,0,0,30,8.44V6.27A.92.92,0,0,0,29.39,5.4ZM3.75,11.25v9.37H2.81a.93.93,0,0,0-.93.94v2.81H28.12V21.56a.93.93,0,0,0-.93-.94h-.94V11.25H22.5v9.37H16.88V11.25H13.12v9.37H7.5V11.25Zm25.31,15H.94a.94.94,0,0,0-.94.94v1.87A.94.94,0,0,0,.94,30H29.06a.94.94,0,0,0,.94-.94V27.19A.94.94,0,0,0,29.06,26.25Z"/></g></g></Logo>


  );
} 

export default PublicNoticesIcon;
